import { Injectable } from '@angular/core';
import {CandidateInfo} from "../dto/candidate-info";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {CalendarEvent} from "angular-calendar";
import {EventDto} from "../dto/event-dto";
import {CandidateSlotsDto} from "../dto/candidate-slots-dto";

@Injectable({
  providedIn: 'root'
})
export class CandidateStateService {

  private candidateInfo
  private url = environment.apiUrl;
  constructor(private http: HttpClient) {}

  public setCandidateInfo(candidate: CandidateInfo){
    this.candidateInfo = candidate
  }

  public getCandidateInfo(){
    return this.candidateInfo;
  }

  authorization(token: string) {
    return this.http.get<any>(this.url + `auth/${token}`);
  }

  saveEvents(candidateSlots: CandidateSlotsDto) {
    return this.http.post<any>(this.url + "slots/save", candidateSlots);
  }
}
