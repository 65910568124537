<div class="content">
  <div class="container">
    <div class="row">
      <div class="custom-col-10">
        <div class="row header">
          <div class="image-col">
            <div class="nav__content">
            <span class="image">
              <img class="col" src="/assets/images/logo.svg" alt="">
            </span>
            </div>
          </div>
          <div class="title-col text-right">
            <div class="name-block">
              {{ userName }}
            </div>
            <div class="name-block availability-small">Interview availability</div>
          </div>
          <div class="col-date text-right">
            <h3>
              <i class="bi bi-arrow-left"
                 mwlCalendarPreviousView
                 style="cursor: pointer; color:#7E8CA3"
                 [view]="calendarView.Week"
                 [(viewDate)]="viewDate"
                 *ngIf="isNotPastWeek()"
                 [daysInWeek]=weekDays></i>
              <span class="date-text"
                style="color: #546480; font-size: 22px;font-weight: 400"> {{ viewDate | date: 'dd MMM yyyy' }}
                - {{ getEndDate() | date: 'dd MMM yyyy' }}</span>
              <i class="bi bi-arrow-right"
                 style="cursor: pointer; color:#7E8CA3"
                 mwlCalendarNextView
                 [view]="calendarView.Week"
                 *ngIf="isNotAfterLimitWeek()"
                 [(viewDate)]="viewDate"
                 [daysInWeek]=weekDays></i>
            </h3>
          </div>
        </div>
        <div class="row">
          <mwl-calendar-week-view
            style="padding-right: 0"
            [viewDate]="viewDate"
            [eventSnapSize]="30"
            [daysInWeek]="weekDays"
            (touchstart)="touchstart()"
            (touchend)="touchend()"
            [events]="events"
            [hourSegmentTemplate]="weekViewHourSegmentTemplate"
            [weekStartsOn]="weekStartsOn"
            [refresh]="refreshAfterDrug"
            [hourSegments]="2"
            [headerTemplate]="headerTemplate"
            [minimumEventHeight]="0"
            (beforeViewRender)="beforeWeekViewRender($event)"
            [validateEventTimesChanged]="validateEventTimesChanged"
            (eventTimesChanged)="eventTimesChanged($event)"
          >
          </mwl-calendar-week-view>
          <div class="bottom-menu">
            <div class="row" style="padding-bottom: 5px" >
              <div class="col-1">
                <i (click)="collapsed = !collapsed; scrollToTop()" style="margin-left: 10px; cursor: pointer" [ngClass]="collapsed? 'bi-chevron-up' : 'bi-chevron-down'"></i>
              </div>
              <div class="col-8">
                <div class="bottom-title">Schedule</div>
                <div class="bottom-title-text">Your free time slots details. {{timezone}}</div>
              </div>
              <div *ngIf="collapsed" class="col-3" style="padding-top: 7px; text-align: right">
                <button (click)="collapsed = !collapsed" class="btn btn-primary">Next</button>
              </div>
            </div>
            <div style="border-top: 1px solid #E4E7ED" class="collapsible row;" [@collapse]="collapsed">
              <div class="col-1"></div>
              <div class="col-11 bottom-content">
                <span *ngIf="!events.length">Choose your first time slot.</span>
                <div style="margin-bottom: 10px" *ngFor="let day of getEventsMap() | keyvalue">
                  <div>
                    <div>{{day.key  | date: 'dd MMM yyyy'}}, {{getWeekDayLong(day.key)}}</div>
                  </div>
                  <div class="row" style="padding-left: 10px">
                    <div class="bottom-timeslot" *ngFor="let event of day.value">
                      <div style="text-align: left; flex: 0 auto; width: 90%">{{event.title}}</div>
                      <div style="font-weight: bold; text-align: right; flex: 0 auto; width: 10%; cursor: pointer" (click)="deleteEvent(event)">x</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div *ngIf="events.length" class="col-12" style="text-align: right; padding-top: 30px">
                    <button [disabled]="inProgress" (click)="saveEvents()" class="btn btn-primary">
                      <span *ngIf="inProgress" class="spinner-border spinner-border-sm"></span>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-col-2 right-menu">
        <h5 style="color: #12213B">Schedule</h5>
        <p style="color: #546480">Your free time slots. {{timezone}}.</p>
        <hr>
        <div class="row">
          <div style="margin-bottom: 10px" class="row" *ngFor="let day of getEventsMap() | keyvalue">
            <div class="timeslot-name">
              <div>{{day.key  | date: 'dd MMM'}}</div>
              <div style="color:#A4AEBF">{{getWeekDay(day.key)}}</div>
            </div>
            <div class="timeslot-value">
              <div *ngFor="let event of day.value">{{event.title}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="!events.length" style="color: #546480">
          Choose your first time slot.
        </div>
        <div class="row button">
          <div class="col-12">
            <button [disabled]="!events.length || inProgress" (click)="saveEvents()" class="btn btn-primary">
              <span *ngIf="inProgress" class="spinner-border spinner-border-sm"></span>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--COLUMN TEMPLATE-->
<ng-template
  #weekViewHourSegmentTemplate
  let-segment="segment"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel">
  <div
    #segmentElement
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass"
    [ngStyle]="isTimeLabel && {'background-color': 'white'}"
    (mousedown)="validateAndStartDragToCreate(segment, segmentElement)"
  >
    <div style="font-weight: 400; height: 16px; font-size: 12px; color: #546480" class="cal-time" *ngIf="isTimeLabel">
      {{ convertToHeaderDateStr(segment.date)}}
    </div>
  </div>
</ng-template>

<!--HEADER TEMPLATE-->
<ng-template #headerTemplate let-days="days" let-locale="locale"
             let-dayClicked="dayClicked" let-eventDropped="eventDropped">
  <div class="cal-day-headers cursor-default" style="padding-left: 0">
    <div class="cal-header"
         style="max-width: 71px;font-size: 11px; padding: 18px 5px 5px 5px; text-align: center; color: #546480">{{timezone}}
    </div>
    <div
      class="cal-header"
      *ngFor="let day of days"
      [class.cal-past]="day.isPast"
      [class.cal-future]="day.isFuture"
      [class.cal-drag-over]="day.dragOver"
      [ngClass]="day.cssClass"
      mwlDroppable
      (dragEnter)="day.dragOver = true"
      (dragLeave)="day.dragOver = false"
      [class.today]="day.isToday"
      [ngStyle]="day.isPast && {'background-color': '#F6F7F9'}"
      (drop)="day.dragOver = false; eventDropped.emit({event: $event.dropData.event, newStart: day.date, fromHeader: true})">
      <div class="top">
        <div>
          <span style="opacity: 1; font-weight: 600;font-size: 24px;"
                [style.color]="day.isPast || day.date >= rangeLimit ? '#A4AEBF' : !day.isToday ? '#546480' : '#F09132'"
                class="day-number">{{ convertToHeaderDate(day.date) | date: 'dd' }}</span>&nbsp;
          <span style="opacity: 1; font-weight: 400;"
                [style.color]="day.isPast || day.date >= rangeLimit ? '#A4AEBF' : !day.isToday ? '#546480' : '#F09132'"
                class="day-name">{{ getWeekDay(day.date)}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
