<div class="content">
  <div class="container">
    <div class="row header">
      <div class="image-col">
        <div class="nav__content">
            <span class="image">
              <img class="col" src="/assets/images/logo.svg" alt="">
            </span>
        </div>
      </div>
    </div>
    <div class="row error">
     <h1 class="title"> <i class="bi-calendar2-x-fill"></i> Page Not Found</h1>
    </div>
  </div>
</div>
